import { PERSIST_STORE_NAME } from '../constance/action.constance'
import { rootReducer } from './rootReducer';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootSaga from "../sagas/Saga";
import createSagaMiddleware from 'redux-saga';
import { createLogger } from "redux-logger";
import { createStore, applyMiddleware, compose } from "redux";

const middleWare = []

const persistConfig = {
    key: PERSIST_STORE_NAME,
    keyPrefix: '',
    storage,
    whitelist: ['auth', 'locale'],
}

export const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
// middleWare.push(
//   thunk.withExtraArgument({
//     api,
//   })
// );

middleWare.push(sagaMiddleware);



const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === "development",
});

middleWare.push(loggerMiddleware);

 export const store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middleWare))
  // applyMiddleware(...middleWare)
);

 


 // then run the saga
 sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
