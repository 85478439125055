import { config_file } from "../../../src/utils/env_config";
export const API_URL_ROOT = config_file[process.env["NODE_ENV"]].API_DOMAIN_NAME;

export const API_URL ={

// 1.LOGIN api
LOGIN:`${API_URL_ROOT}/admin/login`,


// // 2.LOGOUT api
VERIFY_LOGOUT:`${API_URL_ROOT}/admin/logout`,

//3.subscription api
GET_ALL_SUBCRIPTION:`${API_URL_ROOT}/admin/getAllPayments`,

//4.users api :https://vjd.webschedio.in/backend/admin/getAllUsers
GET_ALL_USERS:`${API_URL_ROOT}/admin/getAllUsers`,

//5.add_subscription api
ADD_SUBCRIPTION_PLAN:`${API_URL_ROOT}/admin/addSubscriptionPlans`,

//6.get_all_subscription api
GET_ALL_SUBCRIPTION_PLAN:`${API_URL_ROOT}/admin/getAllSubscriptions`,


//7.getAllSubscriptionReport POST API
GET_SUBCRIPTION_REPORT:`${API_URL_ROOT}/admin/getAllSubscriptionReport`,

//8.getAllSubscriptionReport POST API
GET_PAYMENT_REPORT:`${API_URL_ROOT}/admin/getAllPaymentReport`,

//9. edit subscription api
EDIT_SUBSCRIPTION_PLANE:`${API_URL_ROOT}/admin/updateSubscriptionPlans`,

//10. DELETE subscription api
DELELTE_SUBSCRIPTION_PLANE:`${API_URL_ROOT}/admin/deleteSubscriptionPlans`,

//11. edit subscription api
EDIT_PAYMENET_REPORT_PLANE:`${API_URL_ROOT}/admin/updatePayments`,

//12. edit user api
EDIT_USERS:`${API_URL_ROOT}/admin/updateUsers`,

//13. delete user api
DELETE_USERS:`${API_URL_ROOT}/admin/deleteUsers`,

//14. delete PAYMENT REPORT api
DELETE_PAYMENT:`${API_URL_ROOT}/admin/deletePayments`,

//15. ENABLE DISABLE USERS api
Enable_disable_user:`${API_URL_ROOT}/admin/enableDisableUsers`,

//16.GET ALL PRO USERS API
GET_ALL_PRO_USERS:`${API_URL_ROOT}/admin/getAllProUsers`,

//17.GET ALL PRO USERS API
PRO_USERS_ENABLE_DISABLE:`${API_URL_ROOT}/admin/enableDisableProUsers`,

//18.add_USERS API
ADD_USERS:`${API_URL_ROOT}/admin/addUsers`,

//19.Reset user api
RESET_USERS:`${API_URL_ROOT}/admin/resetuser`,

//20.ADD MASTER ENTRY api
ADD_ASSOCIATION_MASTER_ENTRY:`${API_URL_ROOT}/admin/addAssociation`,

//21.get all MASTER ENTRY API
GET_ALL_MASTER_ENTRY:`${API_URL_ROOT}/admin/getAllAssociation`,

//22.EDIT MASTER ENTRY
EDIT_MASTER_ENTRY:`${API_URL_ROOT}/admin/updateAssociation`,

//23.EDIT MASTER ENTRY
ENABLE_DISABLE_MASTER_ENTRY:`${API_URL_ROOT}/admin/enableDisableAssociation`,

//24.EDIT MASTER ENTRY
IMPORT_USER:`${API_URL_ROOT}/admin/importUser`,

//25.CHANGE PASSWORD
CHANGE_PASSWORD:`${API_URL_ROOT}/admin/changepassword`,

//26.CHANGE PASSWORD
FORGET_PASSWORD:`${API_URL_ROOT}/admin/forgotpassword`,

//26.CHANGE PASSWORD
RESET_PASSWORD:`${API_URL_ROOT}/admin/resetpassword`,

//26.setting PASSWORD
UPDATE_SETTING:`${API_URL_ROOT}/admin/updateSettings`,

//normel user
//27.get all normel user
GET_NORMEL_USER:`${API_URL_ROOT}/admin/getAllOtherUsers`,

//28.enable and disbale normrl user
ENABLE_DISABLE_NORMEL_USER:`${API_URL_ROOT}/admin/enableDisableOtherUsers`,

//28.Edit normrl user
Edit_NORMEL_USER:`${API_URL_ROOT}/admin/updateOtherUsers`,

}