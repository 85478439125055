import { createBrowserHistory } from "history";
import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {

  // all family_members 
  get_all_users_err,
  get_all_users_req,
  get_all_users_success,

  delete_user_success,
  delete_user_request,
  delete_user_error,


  //EDIT USERS FUNCTION
  edit_users_by_user_id_err,
  edit_users_by_user_id_req,
  edit_users_by_user_id_success,

  //EDIT USERS FUNCTION
  enable_disable_user_request,
  enable_disable_user_success,
  enable_disble_error,

  //add users
  add_user_error,
  add_user_request,
  add_user_success,

  //reset users
  reset_user_success,
  reset_user_error,


} from './action.function.js';
import {
  API_URL,
} from '../../api/api.js';
import { API } from '../../../utils/networkApi';
import { isLoading } from '../action.loader/action.function.js';
import {
  GET_ALL_USERS_REQ,
  DELETE_USER_REQUEST,
  EDIT_USERS_BY_USER_ID_REQ,
  Enable_USERS_BY_USER_ID_REQ,
  ADD_USERS_REQ,
  RESET_USER_BY_UNIQUE_ID_REQ,




} from './action.type';
import toaster from "utils/ReactTostify.js";


const history = createBrowserHistory();

function* all_users_Saga(payload) {
  const { data, callback } = payload;
  try {
    const store = yield select();
 
    console.log("store values ==========", API.getHeaders())
    yield put(isLoading(true));
    let response = yield call(API.get, API_URL.GET_ALL_USERS, API.getHeaders());

    // let response = yield call(API.get.API_URL.GET_ALL_USERS,headers);
    yield put(isLoading(false));
    const result = response.data;
    console.log("all  saga result === ", result);
    yield put(get_all_users_success(result));
    callback(null, result);


  }
  catch (error) {
    yield put(get_all_users_err(error));
    yield put(isLoading(false));
    callback(error, null);
  }
}


//DELETE USER

function* delete_user_Saga(payload) {
  const { data, callback } = payload;
  console.log("data sent to delete saga", data)
  try {
    const store = yield select();
  
    console.log("store values ==========", API.getHeaders())
    // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
    yield put(isLoading(true));
    let response = yield call(API.post, API_URL.DELETE_USERS, data, API.getHeaders());
    yield put(isLoading(false));
    const result = response.data;
    console.log("add plan response saga", result);
    yield put(delete_user_success(result))
    callback(null, result);
  }
  catch (error) {
    yield put(delete_user_error(error));
    callback(error, null);
    yield put(isLoading(false));
  }
}


//EDIT users

function* edit_users_Saga(payload) {
  const { data, callback } = payload;
  console.log("data sent to edit plane saga", data)
  try {
 
    yield put(isLoading(true));
    console.log("store values ==========", API.getHeaders())
    let response = yield call(API.post, API_URL.EDIT_USERS, data, API.getHeaders());
    yield put(isLoading(false));

    const result = response.data;
    console.log("add plan response saga", result);
    yield put(edit_users_by_user_id_success(result))
    callback(null, result);

   
  }
  catch (error) {
    yield put(edit_users_by_user_id_err(error));
    callback(error, null);
    yield put(isLoading(false));
  }
}


//ENABLE USER

function* enable_disable_user_Saga(payload) {
  const { data, callback } = payload;
  console.log("data sent to delete saga", data)
  try {
    const store = yield select();

    console.log("store values ==========", API.getHeaders())
    // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
    yield put(isLoading(true));
    let response = yield call(API.post, API_URL.Enable_disable_user, data, API.getHeaders());
    yield put(isLoading(false));
    const result = response.data;
    console.log("add plan response saga", result);
    yield put(enable_disable_user_success(result))
    callback(null, result);
  }
  catch (error) {
    yield put(enable_disble_error(error));
    callback(error, null);
    yield put(isLoading(false));
  }
}



//ADD USER

function* add_user_Saga(payload) {
  const { data, callback } = payload;
  console.log("data sent to delete saga", data)
  try {
    const store = yield select();
  
    console.log("store values ==========", API.getHeaders())
    // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
    yield put(isLoading(true));
    let response = yield call(API.post, API_URL.ADD_USERS, data, API.getHeaders());
    yield put(isLoading(false));
    const result = response.data;
    console.log("add plan response saga", result);
    yield put(add_user_success(result))
    callback(null, result);
  }
  catch (error) {
    yield put(add_user_error(error));
    callback(error, null);
    yield put(isLoading(false));
  }
}

//ENABLE USER

function* reset_user_Saga(payload) {
  const { data, callback } = payload;
  console.log("data sent to delete saga", data)
  try {
    const store = yield select();

    console.log("store values ==========", API.getHeaders())
    // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
    yield put(isLoading(true));
    let response = yield call(API.post, API_URL.RESET_USERS, data, API.getHeaders());
    yield put(isLoading(false));
    const result = response.data;
    console.log("add plan response saga", result);
    yield put(reset_user_success(result))
    callback(null, result);
  }
  catch (error) {
    yield put(reset_user_error(error));
    callback(error, null);
    yield put(isLoading(false));
 
  }
}
export default function* all_user_Saga() {
  yield takeLatest(EDIT_USERS_BY_USER_ID_REQ, edit_users_Saga);
  yield takeEvery(GET_ALL_USERS_REQ, all_users_Saga);
  yield takeEvery(DELETE_USER_REQUEST, delete_user_Saga);
  yield takeEvery(Enable_USERS_BY_USER_ID_REQ, enable_disable_user_Saga);
  yield takeEvery(ADD_USERS_REQ, add_user_Saga);
  yield takeEvery(RESET_USER_BY_UNIQUE_ID_REQ, reset_user_Saga);
} 