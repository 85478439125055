// /*define your type here, so that you can use it in action */

//Example format
// LOGIN USER
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

// // lOGOUT USER
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";


export const UPDATE_USER_IN_STORE = "UPDATE_USER_IN_STORE"


//INIT
export const ADD_TOKEN_TO_STATE_REQUEST = "ADD_TOKEN_TO_STATE_REQUEST"
export const ADD_TOKEN_TO_STATE = "ADD_TOKEN_TO_STATE"
export const QUERY_USER_BY_TOKEN = "QUERY_USER_BY_TOKEN"
export const QUERYING_USER_BY_TOKEN = "QUERYING_USER_BY_TOKEN"
export const QUERYING_USER_BY_TOKEN_SUCCESS = "QUERYING_USER_BY_TOKEN_SUCCESS"
export const QUERYING_USER_BY_TOKEN_ERROR = "QUERYING_USER_BY_TOKEN_ERROR"




