//GET ALL USERS
export const GET_ALL_NORMAL_USERS_REQ = "GET_ALL_NORMAL_USERS_REQ";
export const GET_ALL_NORMAL_USERS_ERR = "GET_ALL_NORMAL_USERS_ERR";
export const GET_ALL_NORMAL_USERS_SUCC ="GET_ALL_NORMAL_USERS_SUCC";

//EDIT users id 
export const EDIT_NORMAL_USERS_BY_USER_ID_REQ  = "EDIT_NORMAL_USERS_BY_USER_ID_REQ";
export const EDIT_NORMAL_USERS_BY_USER_ID_ERR  = "EDIT_NORMAL_USERS_BY_USER_ID_ERR";
export const EDIT_NORMAL_USERS_BY_USER_ID_SUCC = "EDIT_NORMAL_USERS_BY_USER_ID_SUCC";

//Enable Disable users id 
export const Enable_NORMAL_USERS_BY_USER_ID_REQ  = "Enable_NORMAL_USERS_BY_USER_ID_REQ";
export const Enable_NORMAL_USERS_BY_USER_ID_ERR  = "Enable_NORMAL_USERS_BY_USER_ID_ERR";
export const Enable_NORMAL_USERS_BY_USER_ID_SUCC = "Enable_NORMAL_USERS_BY_USER_ID_SUCC";

//Reset users

export const ADD_RESET_NORMAL_USERS_REQ  = "ADD_RESET_NORMAL_USERS_REQ";
export const ADD_RESET_NORMAL_USERS_ERR  = "ADD_RESET_NORMAL_USERS_ERR";
export const ADD_RESET_NORMAL_USERS_SUCC = "ADD_RESET_NORMAL_USERS_SUCC";
