import axios from 'axios'
import errorPage from './RedirectErrorPage';
import {isLoading} from '../redux-services/actions/action.loader/action.function.js';
// const token = localStorage.getItem("auth_token");
// const _headers = {
//   Authorization: "Token " + token,
//   "Content-Type": "application/json"
// };


export const API = {
    getHeaders: () => {
        let token = localStorage.getItem("token");
        let headers = {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        };
        return headers;
      },

      getHeadersAccessControl: () => {
        let token = localStorage.getItem("token");
        let headers = {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': true,
        };
        return headers;
      },

     

      getHeadersFormData: () => {
        let token = localStorage.getItem("token");
        let headersformdata = {
          Authorization: "Bearer " + token,
          // "Content-Type": "application/json"
        };
        return headersformdata;
      },

     
    
  get: (route, headers) => {
    const getPromise = new Promise((resolve, reject) => {
      API.prepareConfig(route, null, 'get', headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    });
    return getPromise;
  },
  post: (route, params, headers) => {
    const postPromise = new Promise((resolve, reject) => {
      API.prepareConfig(route, params, 'post', headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    });
    return postPromise;
  },

  postwithnodata: (route,headers) => {
    const postPromise = new Promise((resolve, reject) => {
      API.prepareConfig(route, null, 'post', headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    });
    return postPromise;
  },

  put: (route, params, headers, callback) => API.prepareConfig(route, params, 'put', headers, callback),
  delete: (route,params, headers) => {
    const deletePromise = new Promise((resolve, reject) => {
      API.prepareConfig(route, params, 'delete', headers,(err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    });
    return deletePromise;
  },

  prepareConfig: async (routeurl, params, methodType, headers={}, callback) => {
    const config = {
      method: methodType,
      url: routeurl,
      data: params,
      headers: headers,

      // timeout: API.requesttimeout,
    };
    API.call(config, callback);
  },

  /*
    * This method will take URL path as string for "routeurl"
    * params will take body: {key: value}, note: not stringify
    * Header:  {key: value}, note: not stringify
    * callback will give us two params (error, responseJson),
      so check for error not equal to null then process json
      else throw alert in your UI by using error parameter.
  */

  call: (config, callback) => {
    axios(config)
      .then((response) => {
        console.log("response axios ======", response);
        callback(null, response);
      })
      .catch((error) => {
          console.log("error axios ======", error);
          console.log("error axios data ======", error.data);
          // isLoading(false);

          if(((error.response.data.message === "Network Error") && !error.response)){
            const errordata = {
              response:{
                status:500
              }
              
            
            }
            errorPage(errordata,config.url);
          }
          else{
            errorPage(error,config.url);
          }
     
          // isLoading(false);

        callback(error, null);
        // isLoading(false);

        throw error;
      });
  },

}
