import { createBrowserHistory } from "history";
import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
     
   //get all pro users
   get_all_pro_users_err,
   get_all_pro_users_req,
   get_all_pro_users_success,

      //get all pro users enable disable
      enable_disable_pro_user_request,
      enable_disble_pro_user_error,
      enable_disable_pro_user_success,
       
   
  } from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import {isLoading} from '../action.loader/action.function.js';
    import { 
       GET_ALL_PRO_USERS_REQ,
       Enable_PRO_USERS_BY_USER_ID_REQ,
    } from './action.type';
    import toaster from "utils/ReactTostify.js";


    const history = createBrowserHistory();
    
    function* pro_users_saga(payload) {
      const {data,callback} =payload;
        try {
            const store = yield select();      
            console.log("store values ==========", API.getHeaders())
        yield put(isLoading(true));
        let response = yield call(API.get,API_URL.GET_ALL_PRO_USERS,API.getHeaders());
          yield put(isLoading(false));
          const result = response.data;
          console.log("all  saga result === ", result);
          yield put(get_all_pro_users_success(result));
          callback(null,result);
        }
        catch (error) {
          yield put(get_all_pro_users_err(error));
          yield put(isLoading(false));
          callback(error,null);
          toaster("error", error);
        }
      }     


                      //enable dsable USER

                      function* pro_users_enable_disable_saga(payload) {
                        const {data,callback} =payload;
                        console.log("data sent to delete saga", data)
                        try {
                            const store = yield select();
                            
                            console.log("store values ==========", API.getHeaders())
                          yield put(isLoading(true));
                          let response = yield call(API.post,API_URL.PRO_USERS_ENABLE_DISABLE,data,API.getHeaders());

                          yield put(isLoading(false));
                          const result  = response.data;
                          console.log("add plan response saga",result);
                          yield put(enable_disable_pro_user_success(result))
                          callback(null,result);
                        }
                        catch(error) {
                          yield put(enable_disble_pro_user_error(error));
                          callback(error,null);
                          yield put(isLoading(false));
                          
                          toaster("error", error);
                        }
                      }
export default function* all_pro_user_Saga() { 
    yield takeEvery(GET_ALL_PRO_USERS_REQ,pro_users_saga);
    yield takeEvery(Enable_PRO_USERS_BY_USER_ID_REQ,pro_users_enable_disable_saga);

} 