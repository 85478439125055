import * as constance from "../../constance/action.constance";
import {  
   // CREATE all USERS
CREATE_MASTER_ENTRY_ERR,
CREATE_MASTER_ENTRY_REQ,
CREATE_MASTER_ENTRY_SUCC,


//get allmaster entry

 GET_ALL_MASTER_ENTRY_REQ,
 GET_ALL_MASTER_ENTRY_ERR,
 GET_ALL_MASTER_ENTRY_SUCC,

 //edit master entry
 EDIT_MASTER_ENTRY_REQ ,
 EDIT_MASTER_ENTRY_ERR  ,
 EDIT_MASTER_ENTRY_SUCC,

 //enableand disable
 ENABLE_MASTER_ENTRY_REQ,
 DISABLE_MASTER_ENTRY_REQ,
 DELETE_MASTER_ENTRY_REQ,
 ENABLE_MASTER_ENTRY_SUCC,
 ENABLE_MASTER_ENTRY_ERR,

//pro users

  } from "./action.type";


  
//ADD USERS

export function add_master_entry_success(result) {
    return {
      type: CREATE_MASTER_ENTRY_SUCC,
      result,
      status: constance.SUCCESS,
    };
  
  }
  export function add_master_entry_request(data,callback) {
    return {
      type:CREATE_MASTER_ENTRY_REQ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  export function add_master_entry_error(error) {
    return {
      type:CREATE_MASTER_ENTRY_ERR,
      error: error,
      status: constance.ERROR,
    };
  }

  // GET ALL  FAMILY_MEMBER
export function get_all_master_entry_req(data,callback) {
    return {
      type: GET_ALL_MASTER_ENTRY_REQ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_master_entry_err(error) {
    return {
      type: GET_ALL_MASTER_ENTRY_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_master_entry_success(result) {
    return {
      type: GET_ALL_MASTER_ENTRY_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }

  // // EDIT_USER_by_user_id_req

export function edit_master_entry_req(data,callback) {
  return {
    type:EDIT_MASTER_ENTRY_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function edit_master_entry_succ(result) {
  return {
    type: EDIT_MASTER_ENTRY_SUCC,
    result,
    status: constance.SUCCESS,
  };

}

export function edit_master_entry_error(error) {
  return {
    type: EDIT_MASTER_ENTRY_ERR,
    error: error,
    status: constance.ERROR,
  };
}




//Enable and disable USERS

export function enable_disable_master_entry_success(result) {
  return {
    type: ENABLE_MASTER_ENTRY_SUCC,
    result,
    status: constance.SUCCESS,
  };

}
export function enable_disable_master_entry_request(data,callback) {
  return {
    type:ENABLE_MASTER_ENTRY_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function enable_disable_master_entry_error(error) {
  return {
    type:ENABLE_MASTER_ENTRY_ERR,
    error: error,
    status: constance.ERROR,
  };
}

