import * as all_usernormel_ActionType from "../../actions/action.normelUser/action.type";

const all_normel_user = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    add_new_user:null,
    normel_users_enable_disable:null,
    all_normel_user_data:null,
    normel_users_edit:null
    },
    action
) =>{

    switch (action.type) {

          // add user get
          case  all_usernormel_ActionType.GET_ALL_NORMAL_USERS_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case  all_usernormel_ActionType.GET_ALL_NORMAL_USERS_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_normel_user_data: action.result,
            });
          case  all_usernormel_ActionType.GET_ALL_NORMAL_USERS_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
  
      

                            // enable user
          case all_usernormel_ActionType.Enable_NORMAL_USERS_BY_USER_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case all_usernormel_ActionType.Enable_NORMAL_USERS_BY_USER_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              normel_users_enable_disable: action.result,
            });
          case all_usernormel_ActionType.Enable_NORMAL_USERS_BY_USER_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
 // EDIT user
            case all_usernormel_ActionType.EDIT_NORMAL_USERS_BY_USER_ID_REQ:
                return Object.assign({}, state, {
                  status: action.status,
                });
              case all_usernormel_ActionType.EDIT_NORMAL_USERS_BY_USER_ID_SUCC:
                return Object.assign({}, state, {
                  status: action.status,
                  normel_users_edit: action.result,
                });
              case all_usernormel_ActionType.EDIT_NORMAL_USERS_BY_USER_ID_ERR:
                return Object.assign({}, state, {
                  status: action.status,
                  error: action.error,
                });

            
                           
        
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default all_normel_user;
    
    
    