import { put, call, post ,select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
    get_subscription_report_by_user_id_req,
    get_subscription_report_by_user_id_err,
    get_subscription_report_by_user_id_success,

    //paymenet status
    get_payment_report_by_user_id_err,
    get_payment_report_by_user_id_req,
    get_payment_report_by_user_id_success,

    //EDIT_payment_report
   edit_payment_report_by_user_id_err,
   edit_payment_report_by_user_id_req,
   edit_payment_report_by_user_id_success,

   //EDIT USERS FUNCTION
   edit_users_by_user_id_err,
   edit_users_by_user_id_req,
   edit_users_by_user_id_success,

   //DELETE PAYMENT REPORT
   delete_payment_report_by_user_id_err,
   delete_payment_report_by_user_id_req,
   delete_payment_report_by_user_id_success,
   delete_payment_success,
   delete_payment_error
  

} from './action.function.js';

    import {API} from '../../../utils/networkApi';
    import {
      
        GET_SUBSCRIPTION_REPORT_BY_USER_ID_REQ,
        GET_PAYMENT_REPORT_BY_USER_ID_REQ,
        EDIT_PAYMENT_REPORT_BY_USER_ID_REQ,
        EDIT_USERS_BY_USER_ID_REQ,
        DELETE_PAYMENT_REPORT_BY_USER_ID_REQ,
        DELETE_PAYMENT_ID_REQ,

    } from './action.type';
    import toaster from "../../../utils/ReactTostify";
    import {isLoading} from '../action.loader/action.function.js';
    import { API_URL } from '../../api/api.js';
    import { createBrowserHistory } from "history";
    

            const history = createBrowserHistory();

          function* subscription_report_id_Saga(payload) {
            const {data,callback} =payload;
            console.log("data sent to get order details by order id saga", data)
            try {

                  const store = yield select();
                  console.log("store values ==========", store.user.token)
                  console.log("store values ==========", API.getHeaders())
              yield put(isLoading(true));
              console.log("h@@@@");
              let response = yield call(API.post,API_URL.GET_SUBCRIPTION_REPORT,data,API.getHeaders());
              console.log("hi console")
              yield put(isLoading(false));
              const result  = response.data;
              console.log("get order details by order id response saga",result);
              yield put(get_subscription_report_by_user_id_success(result))
              callback(null,result);
              }
            catch(error) {
              yield put(get_subscription_report_by_user_id_err(error));
              yield put(isLoading(false));
              callback(error,null);
              toaster("error", error);
            }
          }

          //payment status
          function* payment_report_id_Saga(payload) {
            const {data,callback} =payload;
            console.log("data sent to get order details by order id saga", data)
            try {

                  // const store = yield select();
                  // const headers = {
                  //     Authorization: "Bearer " + store.user.token,
                  //     "Content-Type": "application/json"
                  //   };
                  console.log("store values ==========", API.getHeaders())
              yield put(isLoading(true));
              console.log("h@@@@")
              let response = yield call(API.post,API_URL.GET_PAYMENT_REPORT,data,API.getHeaders());
              console.log("hi console")
              yield put(isLoading(false));
              const result  = response.data;
              console.log("get order details by order id response saga",result);
              yield put(get_payment_report_by_user_id_success(result))
              callback(null,result);
              }
            catch(error) {
              yield put(get_payment_report_by_user_id_err(error));
              yield put(isLoading(false));
              callback(error,null);
              toaster("error", error);
            }
          }


           //EDIT payment

      function* edit_plane_report_Saga(payload) {
        const {data,callback} =payload;
        console.log("data sent to edit plane saga", data)
        try {
            // const store = yield select();
            // const headers = {
            //     Authorization: "Bearer " + store.user.token,
            //     "Content-Type": "application/json"
            //   };
          // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
          yield put(isLoading(true));
          console.log("store values ==========", API.getHeaders())
          let response = yield call(API.post,API_URL.EDIT_PAYMENET_REPORT_PLANE,data,API.getHeaders());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("add plan response saga",result);
          yield put(edit_payment_report_by_user_id_success(result))
          callback(null,result);
        }
        catch(error) {
          yield put(edit_payment_report_by_user_id_err(error));
          callback(error,null);
          yield put(isLoading(false));
        }
      }



          
            //DELETE payment

      function* delete_payment_Saga(payload) {
        const {data,callback} =payload;
        console.log("data sent to delete saga", data)
        try {
            const store = yield select();
            // const headers = {
            //     Authorization: "Bearer " + store.user.token,
            //     "Content-Type": "application/json"
            //   };
            console.log("store values ==========", API.getHeaders())
          // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
          yield put(isLoading(true));
          let response = yield call(API.post,API_URL.DELETE_PAYMENT,data,API.getHeaders());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("add plan response saga",result);
          yield put(delete_payment_success(result))
          callback(null,result);
        }
        catch(error) {
          yield put(delete_payment_error(error));
          callback(error,null);
          yield put(isLoading(false));
          toaster("error", error);
        }
      }
      export default function* paymenet_Saga()
       { 
        yield takeEvery( GET_SUBSCRIPTION_REPORT_BY_USER_ID_REQ, subscription_report_id_Saga);
        yield takeEvery(GET_PAYMENT_REPORT_BY_USER_ID_REQ , payment_report_id_Saga);
        yield takeLatest (EDIT_PAYMENT_REPORT_BY_USER_ID_REQ,edit_plane_report_Saga );
        // yield takeLatest (EDIT_USERS_BY_USER_ID_REQ ,edit_users_Saga);
        yield takeLatest (DELETE_PAYMENT_ID_REQ,delete_payment_Saga) ;
       } 