import React from "react";
// import { Redirect } from "react-router";
import { API_URL } from "../redux-services/api/api.js";
import { createBrowserHistory } from "history";
import { persistor } from "../redux-services/store/index";
import toaster from "./ReactTostify";
import { isLoading } from "../redux-services/actions/action.loader/action.function.js";
import {loadingData} from "../utils/loader";
const history = createBrowserHistory();
// const dispatch = useDispatch();
const errorPage = (error, url) => {
  console.log("error data from error page ===", error);
  const { status, data = {} } = error.response;
  console.log("error status from error page ===", status);

  // const { session = "", message = "" } = data;

  switch (status) {
    //case 401:
      //   if (url !== API_URL.LOGIN) {
      //     localStorage.setItem("user",'');
      //     localStorage.setItem("isLoggedIn",false);
      //  persistor.purge();
      //  localStorage.clear();

      //      history.push("/401");
      //      window.location.reload();
      //     //  isLoading(false)

      //     toaster('error', "Unauthorised");
      //   }
      //   else
      //   {
      //     return;
      //   }

    //  localStorage.setItem("user", "");
     // localStorage.setItem("isLoggedIn", false);
      //  persistor.purge();
      // persistor.purge().then(() => {
      //     return persistor.flush();
      //   }).then(() => {
      //     persistor.pause();
      //   });
     // persistor.pause();
    //persistor.flush().then(() => {
     // return persistor.purge();
    //});
      //localStorage.clear();

      //history.push("/home/login");
      //window.location.reload();
      //  isLoading(false)

      //toaster("error", error.response.data.message);

      //break;
    case 400:
      // persistor.purge();
      isLoading(false);
      loadingData(false);
      toaster("error", "Bad request");
      break;

    case 403:
      isLoading(false);
      loadingData(false);
      toaster("error", "Forbidden");
      break;
    case 500:
      //  dispatch(isLoading(false));
       loadingData(false);
      history.push("/Page500");
      window.location.reload();
      isLoading(false);
      loadingData(false);
      toaster("error", "Opps!!! something went wrong");
      break;
    case 404:
      // history.push("/#/login");
      // window.location.reload();
      isLoading(false);
      loadingData(false);
      toaster("error", "Page not found");
      // persistor.purge();
      history.push("/404");
      window.location.reload();
       isLoading(false)
       loadingData(false);
      break;
      case 412:
            isLoading(false);
            loadingData(false);
          toaster('error', error.response.data.message);
           break;
           case 422:
            isLoading(false);
            loadingData(false);
          toaster('error', error.response.data.message);
           break;
    default:
      return "";
  }

  //   if(status === 400){
  //     return (
  //         <Redirect to={{
  //           pathname: "/400"
  //         }} />
  //       )
  //   }

  //   if(status === 401){
  //     return (
  //         <Redirect to={{
  //           pathname: "/400"
  //         }} />
  //       )

  // }
  // if(status === 404){
  //     return (
  //         <Redirect to={{
  //           pathname: "/404"
  //         }} />
  //       )

  // }
  // if(status === 500){
  //     return (
  //         <Redirect to={{
  //           pathname: "/500"
  //         }} />
  //       )

  // }
};

export default errorPage;
