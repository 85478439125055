import * as change_password_Action_type from "../../actions/action.changePassword/action.type";

const change_password_reducer = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    change_password:null,
    forget_password:null,
    reset_password:null,
    
    },
    action
) =>{

    switch (action.type) {

        
                      // change_password
          case change_password_Action_type.CHANGE_PASSWORD_USERS_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case change_password_Action_type.CHANGE_PASSWORD_USERS__SUCC:
            return Object.assign({}, state, {
              status: action.status,
              change_password: action.result,
            });
          case change_password_Action_type.CHANGE_PASSWORD_USERS__ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });


                         // forget_password
          case change_password_Action_type.FORGET_PASSWORD_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case change_password_Action_type.FORGET_PASSWORD_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              forget_password: action.result,
            });
          case change_password_Action_type.FORGET_PASSWORD_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
             
                            // RESET_password
          case change_password_Action_type.RESET_PASSWORD_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case change_password_Action_type.RESET_PASSWORD_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              reset_password: action.result,
            });
          case change_password_Action_type.RESET_PASSWORD_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
             

        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default change_password_reducer;
    
    
    