import * as report_ActionType from "../../actions/action.Report/action.type";

const report_reducer = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
     //all_report_id
     all_subscription_report: null,
     all_payment_report:null,
     edit_payment_report_data:null,
     edit_user_data:null,
     delete_payment:null,


    },
    action
) =>{

    switch (action.type) {
             //all_subscription_report
          case report_ActionType.GET_SUBSCRIPTION_REPORT_BY_USER_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case report_ActionType.GET_SUBSCRIPTION_REPORT_BY_USER_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_subscription_report: action.result,
            });
          case report_ActionType.GET_SUBSCRIPTION_REPORT_BY_USER_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
            
            //all_payment_report

            case report_ActionType.GET_PAYMENT_REPORT_BY_USER_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case report_ActionType.GET_PAYMENT_REPORT_BY_USER_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_payment_report: action.result,
            });
          case report_ActionType.GET_PAYMENT_REPORT_BY_USER_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });



              // UPDATE PAYMENT REPORT
        case report_ActionType.EDIT_PAYMENT_REPORT_BY_USER_ID_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case report_ActionType.EDIT_PAYMENT_REPORT_BY_USER_ID_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            edit_payment_report_data: action.result,
          });
        case report_ActionType.EDIT_PAYMENT_REPORT_BY_USER_ID_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });


         

             // delete payment
    case report_ActionType.DELETE_PAYMENT_ID_SUCC:
      return Object.assign({}, state, {
        status: action.status,
      });
    case report_ActionType.DELETE_PAYMENT_ID_REQ:
      return Object.assign({}, state, {
        status: action.status,
        delete_payment: action.result,
      });
    case report_ActionType.DELETE_PAYMENT_ID_ERR:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default report_reducer 
    
    
    