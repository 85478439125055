import * as constance from "../../constance/action.constance";
import {  
   // get all family_member
    GET_ALL_PLANE_REQ,
    GET_ALL_PLANE_SUCC,
    GET_ALL_PLANE_ERR,
 

  } from "./action.type";

  // GET ALL  FAMILY_MEMBER
export function get_all_plane_req(data,callback) {
    return {
      type: GET_ALL_PLANE_REQ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_plane_err(error) {
    return {
      type: GET_ALL_PLANE_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_plane_success(result) {
    return {
      type: GET_ALL_PLANE_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }