import * as constance from "../../constance/action.constance";
import {  
   // get all NORMEL USERS
GET_ALL_NORMAL_USERS_ERR,
GET_ALL_NORMAL_USERS_SUCC,
GET_ALL_NORMAL_USERS_REQ,
   
// EDIT USERS_BY_ORDER_ID
EDIT_NORMAL_USERS_BY_USER_ID_REQ,
EDIT_NORMAL_USERS_BY_USER_ID_ERR,
EDIT_NORMAL_USERS_BY_USER_ID_SUCC,

//enabel
Enable_NORMAL_USERS_BY_USER_ID_ERR,
Enable_NORMAL_USERS_BY_USER_ID_REQ,
Enable_NORMAL_USERS_BY_USER_ID_SUCC,

//reset
ADD_RESET_NORMAL_USERS_ERR,
ADD_RESET_NORMAL_USERS_SUCC,
ADD_RESET_NORMAL_USERS_REQ

//pro users

  } from "./action.type";

  // GET ALL  FAMILY_MEMBER
export function get_all_normel_users_req(data,callback) {
    return {
      type: GET_ALL_NORMAL_USERS_REQ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_normel_users_err(error) {
    return {
      type: GET_ALL_NORMAL_USERS_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_normel_users_success(result) {
    return {
      type: GET_ALL_NORMAL_USERS_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }

//EDIT NORMEL USER

export function edit_normel_users_by_user_id_req(data,callback) {
  return {
    type:EDIT_NORMAL_USERS_BY_USER_ID_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function edit_normel_users_by_user_id_success(result) {
  return {
    type: EDIT_NORMAL_USERS_BY_USER_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };

}

export function edit_normel_users_by_user_id_err(error) {
  return {
    type: EDIT_NORMAL_USERS_BY_USER_ID_ERR,
    error: error,
    status: constance.ERROR,
  };
}


//Enable and disable USERS

export function enable_disable_normel_user_success(result) {
  return {
    type: Enable_NORMAL_USERS_BY_USER_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };

}
export function enable_disable_normel_user_request(data,callback) {
  return {
    type:Enable_NORMAL_USERS_BY_USER_ID_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function enable_disble_normel_user_error(error) {
  return {
    type:Enable_NORMAL_USERS_BY_USER_ID_ERR,
    error: error,
    status: constance.ERROR,
  };
}



// //RESET USERS

// export function reset_user_success(result) {
//   return {
//     type: RESET_USER_BY_UNIQUE_ID_SUCC,
//     result,
//     status: constance.SUCCESS,
//   };

// }
// export function reset_user_request(data,callback) {
//   return {
//     type:RESET_USER_BY_UNIQUE_ID_REQ,
//     data,
//     callback,
//     status: constance.REQUESTING,
//   };
// }
// export function reset_user_error(error) {
//   return {
//     type:RESET_USER_BY_UNIQUE_ID_ERR,
//     error: error,
//     status: constance.ERROR,
//   };
// }
