import * as Free_trail_ActionType from "../../actions/action.setting/action.type";
const free_trial_Reducer = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    free_trial_value: null, 
    free_trial_status:null,
    free_trial_error:null,
    },
    action
) =>{
    switch (action.type) {
        //Free_trial_Reducer type     
        case Free_trail_ActionType.FREE_TRIAL_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case Free_trail_ActionType.FREE_TRIAL_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            free_trial_value: action.result,
          });
        case Free_trail_ActionType.FREE_TRIAL_ERR:
          return Object.assign({}, state, {
            free_trial_status: action.status,
            free_trial_error: action.error,
          });
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default free_trial_Reducer;
    
    
    