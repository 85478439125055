import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {  
    add_subscription_plane_success,
    add_subscription_plane_error,

    edit_subscription_plane_error,
    edit_subscription_plane_success,

    delete_subscription_plane_success,
    delete_subscription_plane_error,
  } from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import {isLoading} from '../action.loader/action.function.js';
    import {ADD_SUBSCRIPTION_PLAN_REQUEST} from './action.type';
    import { EDIT_SUBSCRIPTION_PLAN_REQUEST } from './action.type';
    import { DELETE_SUBSCRIPTION_PLAN_REQUEST } from './action.type';
    import toaster from "../../../utils/ReactTostify";
    
   

      function* add_plane_Saga(payload) {
        const {data,callback} =payload;
        console.log("data sent to add family member saga", data)
        try {
            const store = yield select();
            // const headers = {
            //     Authorization: "Bearer " + store.user.token,
            //     "Content-Type": "application/json"
            //   };
            console.log("store values ==========", API.getHeaders())
            
          // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
          yield put(isLoading(true));
          let response = yield call(API.post,API_URL.ADD_SUBCRIPTION_PLAN,data,API.getHeaders());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("add plan response saga",result);
          yield put(add_subscription_plane_success(result))
          callback(null,result);
        }
        catch(error) {
          yield put(add_subscription_plane_error(error));
          callback(error,null);
          yield put(isLoading(false));
          // toaster("error", error);
        }
      }


      //EDIT PLANE

      function* edit_plane_Saga(payload) {
        const {data,callback} =payload;
        console.log("data sent to edit plane saga", data)
        try {
            const store = yield select();
            // const headers = {
            //     Authorization: "Bearer " + store.user.token,
            //     "Content-Type": "application/json"
            //   };
          // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
          yield put(isLoading(true));
          let response = yield call(API.post,API_URL.EDIT_SUBSCRIPTION_PLANE,data,API.getHeaders());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("add plan response saga",result);
          yield put(edit_subscription_plane_success(result))
          callback(null,result);
        }
        catch(error) {
          yield put(edit_subscription_plane_error(error));
          callback(error,null);
          yield put(isLoading(false));
          toaster("error", error);
        }
      }

//DELETE PLANE
    
  function* delete_plane_Saga(payload) {
  const {data,callback} =payload;
  console.log("data sent to delete saga", data)
  try {
      const store = yield select();
      // const headers = {
      //     Authorization: "Bearer " + store.user.token,
      //     "Content-Type": "application/json"
      //   };
    // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
    yield put(isLoading(true));
    
    let response = yield call(API.post,API_URL.DELELTE_SUBSCRIPTION_PLANE,data,API.getHeaders());
    yield put(isLoading(false));
    const result  = response.data;
    console.log("add plan response saga",result);
    yield put(delete_subscription_plane_success(result))
    callback(null,result);
  }
  catch(error) {
    yield put(delete_subscription_plane_error(error));
    callback(error,null);
    yield put(isLoading(false));
    toaster("error", error);
  }
}



    
      
    
      
export default function* plane_Saga() { 
    yield takeEvery(ADD_SUBSCRIPTION_PLAN_REQUEST,    add_plane_Saga);
    yield takeEvery(EDIT_SUBSCRIPTION_PLAN_REQUEST,   edit_plane_Saga);
    yield takeEvery(DELETE_SUBSCRIPTION_PLAN_REQUEST, delete_plane_Saga);
} 