import React from 'react'

import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
// import appConfig from 'configs/app.config'
import { config_file } from "../src/utils/env_config";
import './locales'
import { Provider } from "react-redux";
import { store} from "./redux-services/store";
import {persistor} from './redux-services/store'
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
const environment = process.env.NODE_ENV

if (config_file.enableMock) {
    mockServer({ environment })
}

function App() {
    return (
        <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter history={history}>
            <ToastContainer />
            
                <Theme>
                    <Layout />
                </Theme>
            </BrowserRouter>
        </PersistGate>
    </Provider>
    )
}

export default App

