import * as constance from "../../constance/action.constance";
import {  
   // get all pro USERS
GET_ALL_PRO_USERS_ERR,
GET_ALL_PRO_USERS_SUCC,
GET_ALL_PRO_USERS_REQ,
Enable_PRO_USERS_BY_USER_ID_SUCC,
Enable_PRO_USERS_BY_USER_ID_REQ,
Enable_PRO_USERS_BY_USER_ID_ERR,


  } from "./action.type";

  // GET ALL  FAMILY_MEMBER
export function get_all_pro_users_req(data,callback) {
    return {
      type: GET_ALL_PRO_USERS_REQ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_pro_users_err(error) {
    return {
      type: GET_ALL_PRO_USERS_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_pro_users_success(result) {
    return {
      type: GET_ALL_PRO_USERS_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }

  //Enable and disable USERS

export function enable_disable_pro_user_success(result) {
  return {
    type: Enable_PRO_USERS_BY_USER_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };

}
export function enable_disable_pro_user_request(data,callback) {
  return {
    type:Enable_PRO_USERS_BY_USER_ID_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function enable_disble_pro_user_error(error) {
  return {
    type:Enable_PRO_USERS_BY_USER_ID_ERR,
    error: error,
    status: constance.ERROR,
  };
}