import * as constance from "../../constance/action.constance"
import { 


//  GET ALL ORDERS
  GET_ALL_SUBSCRIPTION_ERR ,
  GET_ALL_SUBSCRIPTION_SUCC,
  GET_ALL_SUBSCRIPTION_REQ,



} from "./action.type";




// GET ALL Subscription

export function get_all_subscription_req(data,callback) {
    return {
      type:GET_ALL_SUBSCRIPTION_REQ,
    data, 
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_subscription_err(error) {
    return {
      type: GET_ALL_SUBSCRIPTION_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_subscription_success(data) {
    return {
      type: GET_ALL_SUBSCRIPTION_SUCC,
      data,
      status: constance.SUCCESS,
    };
  }