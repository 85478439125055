import * as Plane_ActionType from "../../actions/action.plan/action.type";

const planeReducer = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    add_plane_value: null, 
    getall_plane_status:null,
    getall_plane_error:null,
    },
    action
) =>{

    switch (action.type) {
    
        //planeReducer reducers type
        //add new plane
        case Plane_ActionType.ADD_SUBSCRIPTION_PLAN_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case Plane_ActionType.ADD_SUBSCRIPTION_PLAN_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            add_plane_value: action.result,
          });
        case Plane_ActionType.ADD_SUBSCRIPTION_PLAN_ERROR:
          return Object.assign({}, state, {
            getall_plane_status: action.status,
            getall_plane_error: action.error,
          });
         
      
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default planeReducer;
    
    
    