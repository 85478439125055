import * as constance from "../../constance/action.constance";
import {  
    FREE_TRIAL_REQ,
    FREE_TRIAL_ERR,
    FREE_TRIAL_SUCC,
  } from "./action.type";
export function free_trail_success(result) {
  return {
    type:  FREE_TRIAL_SUCC,
    result,
    status: constance.SUCCESS,
  };

}
export function free_trail_request(data,callback) {
  return {
    type: FREE_TRIAL_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function free_trail_error(error) {
  return {
    type: FREE_TRIAL_ERR,
    error: error,
    status: constance.ERROR,
  };
}

