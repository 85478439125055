//GET ALL USERS
export const GET_ALL_USERS_REQ = "GET_ALL_USERS_REQ";
export const GET_ALL_USERS_ERR = "GET_ALL_USERS_ERR";
export const GET_ALL_USERS_SUCC = "GET_ALL_USERS_SUCC";


//delete user
export const DELETE_USER_REQUEST =   "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS =   "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR   =   "DELETE_USER_ERROR";

//EDIT users id 
export const EDIT_USERS_BY_USER_ID_REQ  = "EDIT_USERS_BY_USER_ID_REQ";
export const EDIT_USERS_BY_USER_ID_ERR  = "EDIT_USERS_BY_USER_ID_ERR";
export const EDIT_USERS_BY_USER_ID_SUCC = "EDIT_USERS_BY_USER_ID_SUCC";

//Enable Disable users id 
export const Enable_USERS_BY_USER_ID_REQ  = "Enable_USERS_BY_USER_ID_REQ";
export const Enable_USERS_BY_USER_ID_ERR  = "Enable_USERS_BY_USER_ID_ERR";
export const Enable_USERS_BY_USER_ID_SUCC = "Enable_USERS_BY_USER_ID_SUCC";

//Reset users

export const ADD_USERS_REQ  = "ADD_USERS_REQ";
export const ADD_USERS_ERR  = "ADD_USERS_ERR";
export const ADD_USERS_SUCC = "ADD_USERS_SUCC";

//Add users

export const RESET_USER_BY_UNIQUE_ID_REQ  = "RESET_USER_BY_UNIQUE_ID_REQ ";
export const RESET_USER_BY_UNIQUE_ID_ERR  = "RESET_USER_BY_UNIQUE_ID_ERR";
export const RESET_USER_BY_UNIQUE_ID_SUCC = "RESET_USER_BY_UNIQUE_ID_SUCC ";