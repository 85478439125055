import { createBrowserHistory } from 'history'
import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects'
import {
    //change password
    change_password_error,
    change_password_user_success,

    //forget password
    forget_password_error,
    forget_password_success,

    //reset password
    reset_password_error,
    reset_password_success,
} from './action.function.js'
import { API_URL } from '../../api/api.js'
import { API } from '../../../utils/networkApi'
import { isLoading } from '../action.loader/action.function.js'
import {
    CHANGE_PASSWORD_USERS_REQ,
    FORGET_PASSWORD_REQ,
    RESET_PASSWORD_REQ,
} from './action.type'
import toaster from 'utils/ReactTostify.js'

const history = createBrowserHistory()

//CHANGE PASSWORD

function* change_password_Saga(payload) {
    const { data, callback } = payload
    console.log('data sent to delete saga', data)
    try {
        const store = yield select()

        console.log('store values ==========', API.getHeaders())
        // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
        yield put(isLoading(true))
        let response = yield call(
            API.post,
            API_URL.CHANGE_PASSWORD,
            data,
            API.getHeaders()
        )
        yield put(isLoading(false))
        const result = response.data
        console.log('add plan response saga', result)
        yield put(change_password_user_success(result))
        callback(null, result)
    } catch (error) {
        yield put(change_password_error(error))
        callback(error, null)
        yield put(isLoading(false))
        // toaster("error", error);
    }
}

//FORGET PASSWORD
function* forget_password_Saga(payload) {
    const { data, callback } = payload
    console.log('data sent to delete saga', data)
    console.log('data value of saga ========', payload.data)

    try {
        const store = yield select()

        console.log('store values ==========FORGET_PASSWORD', API.getHeaders())
        // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
        yield put(isLoading(true))
        let response = yield call(API.post, API_URL.FORGET_PASSWORD, data)

        yield put(isLoading(false))
        const result = response.data
        console.log('add plan response saga', result)
        yield put(forget_password_success(result))
        callback(null, result)
    } catch (error) {
        yield put(forget_password_error(error))
        callback(error, null)
        yield put(isLoading(false))
        // toaster("error", error);
    }
}

//RESET PASSWORD
function* reset_password_Saga(payload) {
    const { data, callback } = payload
    console.log('data sent to delete saga', data)
    console.log('data value of saga ========', payload.data)

    try {
        const store = yield select()

        console.log('store values ==========FORGET_PASSWORD', API.getHeaders())
        // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
        yield put(isLoading(true))
        let response = yield call(API.post, API_URL.RESET_PASSWORD, data)

        yield put(isLoading(false))
        const result = response.data
        console.log('add plan response saga', result)
        yield put(reset_password_success(result))
        callback(null, result)
    } catch (error) {
        yield put(reset_password_error(error))
        callback(error, null)
        yield put(isLoading(false))
        // toaster("error", error);
    }
}

export default function* change_password_user_Saga() {
    yield takeEvery(CHANGE_PASSWORD_USERS_REQ, change_password_Saga)
    yield takeEvery(FORGET_PASSWORD_REQ, forget_password_Saga)
    yield takeEvery(RESET_PASSWORD_REQ, reset_password_Saga)
}
