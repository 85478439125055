import * as all_plane_ActionType from "../../actions/action.allPlane/action.type";

const allPlane = (
         //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    all_plane: null,

    },
    action
) =>{

    switch (action.type) {

          // all family_members get
          case all_plane_ActionType.GET_ALL_PLANE_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case all_plane_ActionType.GET_ALL_PLANE_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_plane: action.result,
            });
          case all_plane_ActionType.GET_ALL_PLANE_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default allPlane;
    
    
    