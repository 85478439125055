// import * as userActionType from "../../actions/action.users/action.type";
import * as userActionType from "../../../actions/action.users/action.type";

import { normalizeUser } from "../../../../utils/selectors";

// default user status 
const defaultUser = {
  username: "",
  id: null
};

const defaultState = {
  user:null,
  token:'',
  status: null,
  error: null,
  requesting: null,
  all_users: [],
  isLoggedIn: false,
  checkedForToken: false,
  user_type_id:'',
  assnName:'',
  assnShortCut:'',
  assnLogo:'',
  userId:null,
  // user view
  user_by_id: [],
  user_by_id_loading: false,
  user_by_id_error: null,

  update_user_details: null,
  user: { ...defaultUser }

}
const users = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    
   // login status of the user  
    case userActionType.LOGIN_REQUEST:
      return Object.assign({}, state, {
        status: null,
        error : null,
        requesting: true,
        // isLoggedIn: false
      });
    case userActionType.LOGIN_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
        requesting: false,
        // isLoggedIn: false,
        user: {defaultUser},
        token:''
      });
    case userActionType.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        user: action.success,
        token:action.token,
        status:action.status,
        userId:action.success.userId,
        userId:action.userId,
        assnName:action.assnName,
        assnShortCut:action.assnShortCut,
        assnLogo:action.assnLogo,
        user_by_id:action.user_by_id,
        token:action.success.token,
        isLoggedIn: true,
        requesting: false,
        error: null

        // status: action.status,
        // user: action.success,
        // isLoggedIn: true,
        // requesting: false,
        // error: null
      });


     
      // verifying the admin by token
    case userActionType.QUERYING_USER_BY_TOKEN:
      return {
        ...state,
        pending: true,
        checkedForToken: true
      }
    case userActionType.QUERYING_USER_BY_TOKEN_SUCCESS:
      return {
        ...state,
        pending: false,
        isLoggedIn: true,
        user: normalizeUser(action.payload)
      }
    case userActionType.QUERYING_USER_BY_TOKEN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      }


      //logout status of the user
      // case userActionType.LOGOUT_REQUEST:
      //   return Object.assign({}, state, {
      //     status: null,
      //     error: null,
      //     requesting: true,
      //     isLoggedIn: true
      //   });
      // case userActionType.LOGOUT_FAILURE:
      //   return Object.assign({}, state, {
      //     status: action.status,
      //     error: "error occured",
      //     requesting: false,
      //     isLoggedIn: true
      //   });
      // case userActionType.LOGOUT_SUCCESS:
      //   return Object.assign({}, state, {
      //    status: action.status,
      //     user: { ...defaultUser },
      //     token:'',
      //     user_by_id:'',
      //     isLoggedIn: false,
      //     requesting: false,
      //     error: null
      //   });
      
   // updating the user credential in the store
   case userActionType.UPDATE_USER_IN_STORE:
    return {
      ...state,
      user: normalizeUser({ ...state.user, ...action.payload })
    }
    //case userActionType:
    default:
      return state;

  }
}
export default users;
