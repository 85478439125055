import { combineReducers } from "redux";
import theme from "../../redux-services/store/theme/themeSlice";
import auth from "../../redux-services/store/auth"
import base from '../../redux-services/store/base'
import locale from '../../redux-services/store/locale/localeSlice'
import users from "../reducers/reducer.users/reducer.users/userReducer";
import subscription from "redux-services/reducers/reducer.subsciption/orderSubscrption";
import all_user from "redux-services/reducers/reducers.allusers/all_users";
import planeReducer from "redux-services/reducers/reducer.plane/plane";
import allPlane from "redux-services/reducers/reducer.allPlane/allPlane";
import report_reducer from "redux-services/reducers/reducers.report/report_reducer";
import logout from "redux-services/reducers/reducer.logout/logoutReducer";
import all_pro_user from "redux-services/reducers/reducers.allusers/all_users";
import all_association_user_master_entry_reducer from "redux-services/reducers/reducer.masterEntry/associationMasterEntry";
import all_imports from "redux-services/reducers/reducer.excel/importExcel";
import change_password_reducer from "redux-services/reducers/reducer.changePassword/changePassword";
import free_trial_Reducer from "redux-services/reducers/reducer.setting/setting";
import all_normel_user from "redux-services/reducers/reducer.normalUser/normalUser";
export const rootReducer = combineReducers({
  user: users,
  theme:theme,
  auth :auth,
  base :base,
  locale:locale,
  subscription:subscription,
  all_user:all_user,
  planeReducer:planeReducer,
  allPlane:allPlane,
  report_reducer:report_reducer,
  logout:logout,
  all_pro_user:all_pro_user,
  all_association_user_master_entry_reducer:all_association_user_master_entry_reducer,
  all_imports:all_imports,
  change_password_reducer:change_password_reducer,
  free_trial_Reducer : free_trial_Reducer,
  all_normel_user : all_normel_user,
  
});