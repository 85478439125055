import * as constance from "../../constance/action.constance";
import {  
   // get all USERS
GET_ALL_USERS_REQ ,
GET_ALL_USERS_ERR ,
GET_ALL_USERS_SUCC,
   
//DELETE USERS
DELETE_USER_SUCCESS,
DELETE_USER_ERROR,
DELETE_USER_REQUEST,

// EDIT USERS_BY_ORDER_ID
EDIT_USERS_BY_USER_ID_ERR,
EDIT_USERS_BY_USER_ID_REQ,
EDIT_USERS_BY_USER_ID_SUCC,
Enable_USERS_BY_USER_ID_SUCC,
Enable_USERS_BY_USER_ID_ERR,
Enable_USERS_BY_USER_ID_REQ,
ADD_USERS_SUCC,
ADD_USERS_REQ,
ADD_USERS_ERR,
RESET_USER_BY_UNIQUE_ID_REQ,
RESET_USER_BY_UNIQUE_ID_ERR,
RESET_USER_BY_UNIQUE_ID_SUCC,

//pro users

  } from "./action.type";

  // GET ALL  
export function get_all_users_req(data,callback) {
    return {
      type: GET_ALL_USERS_REQ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_users_err(error) {
    return {
      type: GET_ALL_USERS_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_users_success(result) {
    return {
      type: GET_ALL_USERS_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }


//DELETE USERS

export function delete_user_success(result) {
  return {
    type: DELETE_USER_SUCCESS,
    result,
    status: constance.SUCCESS,
        };

}
export function delete_user_request(data,callback) {
  return {
    type: DELETE_USER_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function delete_user_error(error) {
  return {
    type:DELETE_USER_ERROR,
    error: error,
    status: constance.ERROR,
  };
}


// EDIT_USER_by_user_id_req

export function edit_users_by_user_id_req(data,callback) {
  return {
    type:EDIT_USERS_BY_USER_ID_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function edit_users_by_user_id_success(result) {
  return {
    type: EDIT_USERS_BY_USER_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };

}

export function edit_users_by_user_id_err(error) {
  return {
    type: EDIT_USERS_BY_USER_ID_ERR,
    error: error,
    status: constance.ERROR,
  };
}


//Enable and disable USERS

export function enable_disable_user_success(result) {
  return {
    type: Enable_USERS_BY_USER_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };

}
export function enable_disable_user_request(data,callback) {
  return {
    type:Enable_USERS_BY_USER_ID_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function enable_disble_error(error) {
  return {
    type:Enable_USERS_BY_USER_ID_ERR,
    error: error,
    status: constance.ERROR,
  };
}


//ADD USERS

export function add_user_success(result) {
  return {
    type: ADD_USERS_SUCC,
    result,
    status: constance.SUCCESS,
  };

}
export function add_user_request(data,callback) {
  return {
    type:ADD_USERS_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function add_user_error(error) {
  return {
    type:ADD_USERS_ERR,
    error: error,
    status: constance.ERROR,
  };
}

//RESET USERS

export function reset_user_success(result) {
  return {
    type: RESET_USER_BY_UNIQUE_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };

}
export function reset_user_request(data,callback) {
  return {
    type:RESET_USER_BY_UNIQUE_ID_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function reset_user_error(error) {
  return {
    type:RESET_USER_BY_UNIQUE_ID_ERR,
    error: error,
    status: constance.ERROR,
  };
}
