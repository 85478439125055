//change password
export const CHANGE_PASSWORD_USERS_REQ  =  "CHANGE_PASSWORD_USERS_REQ";
export const CHANGE_PASSWORD_USERS__ERR  = "CHANGE_PASSWORD_USERS_ERR";
export const CHANGE_PASSWORD_USERS__SUCC = "CHANGE_PASSWORD_USERS_SUCC";

//FORGET password
export const FORGET_PASSWORD_REQ  = "FORGET_PASSWORD_REQ";
export const FORGET_PASSWORD_ERR  = "FORGET_PASSWORD_ERR";
export const FORGET_PASSWORD_SUCC = "FORGET_PASSWORD_SUCC";


//RESET password
export const RESET_PASSWORD_REQ  = "RESET_PASSWORD_REQ";
export const RESET_PASSWORD_ERR  = "RESET_PASSWORD_ERR";
export const RESET_PASSWORD_SUCC = "RESET_PASSWORD_SUCC";