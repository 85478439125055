export const config_file = {
    development: 
    {
        DOMAIN_NAME:    "https://vjd.webschedio.in",
        API_DOMAIN_NAME: "https://vjd.webschedio.in/backend",
    },
    production: {
        DOMAIN_NAME:    "https://vjd.webschedio.in",
        API_DOMAIN_NAME: "https://vjd.webschedio.in/backend",
       
      },
      staging: {
        DOMAIN_NAME:    "https://vjd.webschedio.in",
        API_DOMAIN_NAME: "https://vjd.webschedio.in/backend",
      },
      test: {
        DOMAIN_NAME:    "https://vjd.webschedio.in",
        API_DOMAIN_NAME: "https://vjd.webschedio.in/backend",

  
      },
   
  };
  