//addmaster entry for association users

export const CREATE_MASTER_ENTRY_REQ  = "CREATE_MASTER_ENTRY_REQ ";
export const CREATE_MASTER_ENTRY_ERR  = "CREATE_MASTER_ENTRY_ERR";
export const CREATE_MASTER_ENTRY_SUCC = "CREATE_MASTER_ENTRY_SUCC ";


//get all master entry

export const GET_ALL_MASTER_ENTRY_REQ = "GET_ALL_MASTER_ENTRY_REQ";
export const GET_ALL_MASTER_ENTRY_ERR = "GET_ALL_MASTER_ENTRY_ERR";
export const GET_ALL_MASTER_ENTRY_SUCC = "GET_ALL_MASTER_ENTRY_SUCC";


//EDIT master entry

export const EDIT_MASTER_ENTRY_REQ = "EDIT_MASTER_ENTRY_REQ";
export const EDIT_MASTER_ENTRY_ERR = "EDIT_MASTER_ENTRY_ERR";
export const EDIT_MASTER_ENTRY_SUCC = "EDIT_MASTER_ENTRY_SUCC";

//ENABLE AND DISABLE master entry

export const ENABLE_MASTER_ENTRY_REQ = "ENABLE_MASTER_ENTRY_REQ";
export const ENABLE_MASTER_ENTRY_ERR = "ENABLE_MASTER_ENTRY_ERR";
export const ENABLE_MASTER_ENTRY_SUCC = "ENABLE_MASTER_ENTRY_SUCC";