import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
    

    get_all_subscription_req,
    get_all_subscription_err,
    get_all_subscription_success,

  


} from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import {
    
       GET_ALL_SUBSCRIPTION_REQ
     

    } from './action.type';
    import toaster from "../../../utils/ReactTostify";
    import {isLoading} from '../action.loader/action.function.js';




function* allsubscriptionSaga(payload) {
  const {data,callback} =payload;
      try {
          const store = yield select();
        // const headers = {
        //     Authorization: "Bearer " + store.user.token,
        //     "Content-Type": "application/json"
        //   };
          // const userId =  store.user.user?.userId;
      console.log("store values ==========@@@", store.user.token)
      console.log("store values ==========", API.getHeaders())


      yield put(isLoading(true));
        let response = yield call(API.get,API_URL.GET_ALL_SUBCRIPTION,API.getHeaders());
        // console.log("token value",headers)
        yield put(isLoading(false));
        const result  = response.data.response;
        console.log("all orders saga result === ",response.data);
        yield put(get_all_subscription_success(result));
        callback(null,result);
        
       
      }
      catch (error) {
        yield put(get_all_subscription_err(error));
        console.log("errrororororor",error)
         callback(null,error);
        yield put(isLoading(false));
      }
    }

    export default function* my_subscriptionSaga() 
    { 
        
        yield takeEvery( GET_ALL_SUBSCRIPTION_REQ, allsubscriptionSaga);

        
    } 