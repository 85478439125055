import { createBrowserHistory } from "history";
import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {

  //add master entry
add_master_entry_error,
add_master_entry_success,

//GET ALL MASTER ENTRY
get_all_master_entry_err,
get_all_master_entry_success,

//EDIT MASTER ENTRY 
edit_master_entry_error,  
edit_master_entry_succ,

//enable/disablemaster entry
enable_disable_master_entry_error,
enable_disable_master_entry_success,


} from './action.function.js';
import {
  API_URL,
} from '../../api/api.js';
import { API } from '../../../utils/networkApi';
import { isLoading } from '../action.loader/action.function.js';
import {
CREATE_MASTER_ENTRY_REQ,
GET_ALL_MASTER_ENTRY_REQ,
EDIT_MASTER_ENTRY_REQ,
ENABLE_MASTER_ENTRY_REQ,




} from './action.type';
import toaster from "utils/ReactTostify.js";


const history = createBrowserHistory();


//ADD_ASSOCIATION

function* add_association_user_Saga(payload) {
    const { data, callback } = payload;
    console.log("data sent to delete saga", data)
    try {
      const store = yield select();
    
      console.log("store values ==========", API.getHeaders())
      // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
      yield put(isLoading(true));
      let response = yield call(API.post, API_URL.ADD_ASSOCIATION_MASTER_ENTRY, data, API.getHeaders());
      yield put(isLoading(false));
      const result = response.data;
      console.log("add plan response saga", result);
      yield put(add_master_entry_success(result))
      callback(null, result);
    }
    catch (error) {
      yield put(add_master_entry_error(error));
      callback(error, null);
      yield put(isLoading(false));
      toaster("error", error);
    }
  }



  //GET ALLMASTER ENTRY

function* get_all_master_entries_Saga(payload) {
  const { data, callback } = payload;
  try {
    const store = yield select();
 
    console.log("store values ==========", API.getHeaders())
    yield put(isLoading(true));
    let response = yield call(API.get, API_URL.GET_ALL_MASTER_ENTRY, API.getHeaders());

    // let response = yield call(API.get.API_URL.GET_ALL_USERS,headers);
    yield put(isLoading(false));
    const result = response.data;
    console.log("all  saga result === ", result);
    yield put( get_all_master_entry_success(result));
    callback(null, result);


  }
  catch (error) {
    yield put(get_all_master_entry_err(error));
    yield put(isLoading(false));
    callback(error, null);
    toaster("error", error);
  }
}


// //EDIT users

function* edit_master_entry_Saga(payload) {
  const { data, callback } = payload;
  console.log("data sent to edit plane saga", data)
  try {
 
    yield put(isLoading(true));
    console.log("store values ==========", API.getHeaders())
    let response = yield call(API.post, API_URL.EDIT_MASTER_ENTRY, data, API.getHeaders());
    yield put(isLoading(false));

    const result = response.data;
    console.log("add plan response saga", result);
    yield put(edit_master_entry_succ(result))
    callback(null, result);

   
  }
  catch (error) {
    yield put(edit_master_entry_error(error));
    callback(error, null);
    yield put(isLoading(false));
    toaster("error", error);
  }
}


//ENABLE USER

function* enable_master_entry_Saga(payload) {
  const { data, callback } = payload;
  console.log("data sent to delete saga", data)
  try {
    const store = yield select();

    console.log("store values ==========", API.getHeaders())
    // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
    yield put(isLoading(true));
    let response = yield call(API.post, API_URL.ENABLE_DISABLE_MASTER_ENTRY, data, API.getHeaders());
    yield put(isLoading(false));
    const result = response.data;
    console.log("enble master entry response saga", result);
    yield put(enable_disable_master_entry_success(result))
    callback(null, result);
  }
  catch (error) {
    yield put(enable_disable_master_entry_error(error));
    callback(error, null);
    yield put(isLoading(false));
    toaster("error", error);
  }
}
export default function* all_association_user_Saga() {

  yield takeEvery(CREATE_MASTER_ENTRY_REQ, add_association_user_Saga);
  yield takeEvery(GET_ALL_MASTER_ENTRY_REQ, get_all_master_entries_Saga);
  yield takeEvery(EDIT_MASTER_ENTRY_REQ, edit_master_entry_Saga);
  yield takeEvery(ENABLE_MASTER_ENTRY_REQ, enable_master_entry_Saga);

  

} 