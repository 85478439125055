import * as constance from "../../constance/action.constance";
import {  
//import users
IMPORT_USERS_ERR,
IMPORT_USERS_SUCC,
IMPORT_USERS_REQ,
  } from "./action.type";

  // GET ALL  FAMILY_MEMBER
export function import_all_users_req(data,callback) {
    return {
      type: IMPORT_USERS_REQ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function import_all_users_success(result) {
    return {
      type: IMPORT_USERS_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }
   
  export function import_all_users_err(error) {
    return {
      type: IMPORT_USERS_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
 


