import * as subscriptionActionType from "../../actions/action.subscription/action.type";


const subscription = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    //  getallorders_error: null,
    // getallorders_status: null,
    // order,time_slot,coupons
    // get_all_subscription:[],
    all_subscription:{},
    },
    action
) =>{
    switch (action.type) {
           // get all orders
           case subscriptionActionType.GET_ALL_SUBSCRIPTION_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case subscriptionActionType.GET_ALL_SUBSCRIPTION_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_subscription: action.result,
            });
          case subscriptionActionType.GET_ALL_SUBSCRIPTION_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default subscription;
    
    
    