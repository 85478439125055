import * as constance from "../../constance/action.constance";
import {  
  
    // Add SUBSCRIPTION PLAN
    ADD_SUBSCRIPTION_PLAN_REQUEST,
    ADD_SUBSCRIPTION_PLAN_SUCCESS,
    ADD_SUBSCRIPTION_PLAN_ERROR,

    //EDIT SUBSCRIPTION PLAN
    EDIT_SUBSCRIPTION_PLAN_REQUEST,
    EDIT_SUBSCRIPTION_PLAN_SUCCESS,
    EDIT_SUBSCRIPTION_PLAN_ERROR,

    //DELETE SUBSCRIPTION PLAN
    DELETE_SUBSCRIPTION_PLAN_ERROR,
    DELETE_SUBSCRIPTION_PLAN_REQUEST,
    DELETE_SUBSCRIPTION_PLAN_SUCCESS

  } from "./action.type";
export function add_subscription_plane_success(result) {
  return {
    type: ADD_SUBSCRIPTION_PLAN_SUCCESS,
    result,
    status: constance.SUCCESS,
  };

}
export function add_subscription_plane_request(data,callback) {
  return {
    type: ADD_SUBSCRIPTION_PLAN_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function add_subscription_plane_error(error) {
  return {
    type: ADD_SUBSCRIPTION_PLAN_ERROR,
    error: error,
    status: constance.ERROR,
  };
}

//edit plane

export function edit_subscription_plane_success(result) {
  return {
    type: EDIT_SUBSCRIPTION_PLAN_SUCCESS,
    result,
    status: constance.SUCCESS,
  };

}
export function edit_subscription_plane_request(data,callback) {
  return {
    type:EDIT_SUBSCRIPTION_PLAN_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function edit_subscription_plane_error(error) {
  return {
    type: EDIT_SUBSCRIPTION_PLAN_ERROR,
    error: error,
    status: constance.ERROR,
  };
}

//DELETE plane

export function delete_subscription_plane_success(result) {
  return {
    type: DELETE_SUBSCRIPTION_PLAN_SUCCESS,
    result,
    status: constance.SUCCESS,
  };

}
export function delete_subscription_plane_request(data,callback) {
  return {
    type: DELETE_SUBSCRIPTION_PLAN_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function delete_subscription_plane_error(error) {
  return {
    type:DELETE_SUBSCRIPTION_PLAN_ERROR,
    error: error,
    status: constance.ERROR,
  };
}
