import * as all_user_ActionType from "../../actions/action.allusers/action.type";

const all_user = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    add_new_user:null,
    reset_users:null,
    all_user_data:null,
    },
    action
) =>{

    switch (action.type) {

          // add user get
          case all_user_ActionType.GET_ALL_USERS_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case all_user_ActionType.GET_ALL_USERS_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_user_data: action.result,
            });
          case all_user_ActionType.GET_ALL_USERS_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
  
                      // add user get
          case all_user_ActionType.ADD_USERS_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case all_user_ActionType.ADD_USERS_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              add_new_user: action.result,
            });
          case all_user_ActionType.ADD_USERS_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });

                            // reset user
          case all_user_ActionType.RESET_USER_BY_UNIQUE_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case all_user_ActionType.RESET_USER_BY_UNIQUE_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              reset_users: action.result,
            });
          case all_user_ActionType.RESET_USER_BY_UNIQUE_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default all_user;
    
    
    