import { toast } from "react-toastify"
import preciseError from "./ErrorHandler";

const toaster = (type, message)=> 
             {
                preciseError(type, message).then(msg=>{
                    switch(type){
                        case "success":
                           toast(msg,
                               {
                               position: "top-center",
                               autoClose: 3000,
                               hideProgressBar: false,
                               closeOnClick: true,
                               pauseOnHover: true,
                               draggable: true,
                               progress: undefined,
                            //    type:"dark"
                               }
                               );
                               break;
                       case "error":
                           toast.error(msg,
                               {
                               position: "top-right",
                               autoClose: 5000,
                               hideProgressBar: false,
                               closeOnClick: true,
                               pauseOnHover: true,
                               draggable: true,
                               progress: undefined,
                               }
                               );
                               break;
                       case "warn":
                           toast.warn(msg,
                               {
                               position: "top-center",
                               autoClose: 3000,
                               hideProgressBar: false,
                               closeOnClick: true,
                               pauseOnHover: true,
                               draggable: true,
                               progress: undefined,
                               }
                               );
                               break;
                       case "info":
                           toast.info(msg,
                               {
                               position: "top-center",
                               autoClose: 3000,
                               hideProgressBar: false,
                               closeOnClick: true,
                               pauseOnHover: true,
                               draggable: true,
                               progress: undefined,
                               }
                               );
                               break;
   
                               default:
                                   return null
                    }
                   })
                
                }
              

              export default toaster;