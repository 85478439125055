import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
     
    // all family_members 
    get_all_plane_req,
    get_all_plane_success,
    get_all_plane_err,

  
  } from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import {isLoading} from '../action.loader/action.function.js';
    import { 
         GET_ALL_PLANE_REQ ,
         EDIT_USERS_BY_USER_ID_REQ,

       

    } from './action.type';
    import toaster from "../../../utils/ReactTostify";
    
    function* allPlane_Saga(payload) {
      const {data,callback} =payload;
        try {
            const store = yield select();
            // const headers = {
            //     Authorization: "Bearer " + store.user.token,
            //     "Content-Type": "application/json"
            //   };
           
            // const userId =  store.user.user?.userId;
        // console.log("store values ==========", store?.user?.user?.userId)
        // console.log("api url concatication =============",(API_URL.ALL_FAMILY_MEMBERS + store?.user?.user?.userId))
        console.log("store values ==========", API.getHeaders())
        console.log("store values ==========", API.getHeaders())
        yield put(isLoading(true));
          let response = yield call(API.get,API_URL.GET_ALL_SUBCRIPTION_PLAN,API.getHeaders());
          yield put(isLoading(false));
          const result = response.data;
          console.log("all family_member saga result === ", result);
          yield put(get_all_plane_success(result));
          callback(null,result);
          
         
        }
        catch (error) {
          yield put(get_all_plane_err(error));
          yield put(isLoading(false));
          callback(error,null);
          toaster("error", error);
        }
      }

    
      
    
      
export default function* all_plane_Saga() { 
    yield takeEvery(GET_ALL_PLANE_REQ, allPlane_Saga);
  
} 