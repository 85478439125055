import { all } from "redux-saga/effects";
import userSaga from "../actions/action.users/userSaga.js"
import my_subscriptionSaga from "redux-services/actions/action.subscription/userSaga.js";
import all_user_Saga from "redux-services/actions/action.allusers/actionSaga.js";
import plane_Saga from "redux-services/actions/action.plan/planSaga.js";
import all_plane_Saga from "redux-services/actions/action.allPlane/userSaga.js";
import paymenet_Saga from "redux-services/actions/action.Report/actionSaga.js";
import check_logout_Saga from "redux-services/actions/action.logout/action.saga.js";
import all_pro_user_Saga from "redux-services/actions/action.prousers/action.saga.js";
import all_association_user_Saga from "redux-services/actions/action.masterEntry/action.saga.js";
import all_import_user_Saga from "redux-services/actions/action.excel/action.saga.js";
import change_password_user_Saga from "redux-services/actions/action.changePassword/action.saga.js";
import free_trail_Saga from "redux-services/actions/action.setting/settingSaga.js";
import all_user_normel_Saga from "redux-services/actions/action.normelUser/actionSaga.js";

/**D:
 * Root saga
//  * @returns { IterableIterator<AllEffect | GenericAllEffect<any> | *> }
 */
export default function* rootSaga() {
  yield all([
    userSaga(),
    my_subscriptionSaga(),
    all_user_Saga(),
    plane_Saga(),
    all_plane_Saga(),
    paymenet_Saga(),
    check_logout_Saga(),
    all_pro_user_Saga(),
    all_association_user_Saga(),
    all_import_user_Saga(),
    change_password_user_Saga(),
    free_trail_Saga(),
    all_user_normel_Saga(),


            ]);
  
}