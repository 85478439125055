import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { createBrowserHistory } from 'history'
import {
    logout_error,
    logout_request,
    logout_success,
} from './action.function.js'
import { API_URL } from '../../api/api.js'
import { API } from '../../../utils/networkApi'
import { isLoading } from '../action.loader/action.function.js'
import { persistor } from '../../store/index'
import { useHistory } from 'react-router-use-history'
import toaster from 'utils/ReactTostify.js'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { POST_LOGOUT_REQUEST } from './action.type.js'

const history = createBrowserHistory()
function* logout_Saga(payload) {
    const { data, callback } = payload
    console.log('data sent to check logout  saga', data)
    try {
        const store = yield select()

        console.log('store values ==========', API.getHeaders())
        console.log('store values ==========@@@', store.user.token)

        let response = yield call(
            API.get,
            API_URL.VERIFY_LOGOUT,
            API.getHeaders()
        )
        yield put(isLoading(true))
        localStorage.removeItem('token')
        localStorage.clear()

        persistor.pause()
        persistor.flush().then(() => {
            return persistor.purge()
        })
        yield put(isLoading(false))

        // history.push('./sign-in');
        // window.location.reload();

        const result = response.data
        console.log(' check logout  saga', result)
        yield put(logout_success(result))
        callback(null, result)
        toast.success('Log out successful', {
            position: toast.POSITION.BOTTOM_RIGHT,
        })
    } catch (error) {
        yield put(logout_error(error))
        callback(error, null)
        yield put(isLoading(false))
        // toaster("error", error);
    }
}

export default function* check_logout_Saga() {
    yield takeEvery(POST_LOGOUT_REQUEST, logout_Saga)
}
