import React, { memo, useMemo, lazy, Suspense } from 'react'
import { Loading } from 'components/shared'
import { useSelector, } from 'react-redux';
// import { isLoading } from '../../../redux-services/actions/action.loader/action.function.js';

import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
    
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import { Location, useLocation } from 'react-router';
import { useState } from 'react';
const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = (props) => {
    const location=useLocation();
    const login_value=props.loged_in;
    // console.log("is  logeddin",login_value)
    const layoutType = useSelector((state) => state.theme.layout.type)
    var condtion_login=useSelector((state)=>state.user.isLoggedIn)
    if (!condtion_login) {
        let token = localStorage.getItem("token")
        console.log("token add to state check token ", token)
        // verify the expiry time too ----- todo
        if (token) {
            condtion_login = true
        }
    }
    // const [login_req,setLoginReq]= useState(false);
const [login_state,setLogin]=useState(false);

    const { authenticated } = useAuth()
    // console.log("authenticated",login_req)
console.log("authenticated",condtion_login)
// console.log("authenticated",authenticated)
    useDirection()

    useLocale()

    // const AppLayout = useMemo(() => {return layouts[layoutType]}, [layoutType, authenticated])
    //     if (true) 
    //     {
        // (props.user?.userId != null)
    //         return layouts[layoutType]
    //     }
    //     return lazy(() => import('./AuthLayout'))
    // }, [layoutType, authenticated])
    const AppLayout = useMemo(() => {
        if (condtion_login) {
            return layouts[layoutType]
        }
        return lazy(() => import('./AuthLayout'))
    }, [layoutType, condtion_login])

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    )
}

export default memo(Layout)
