import * as all_association_user_ActionType from "../../actions/action.masterEntry/action.type";

const all_association_user_master_entry_reducer = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    all_association_master_entry:null,
    get_all_master_entry_data:null,
    edit_association:null,
    enableDisableMasterEntry:null,
    
    },
    action
) =>{

    switch (action.type) {

          //get all master entries  

          case all_association_user_ActionType.GET_ALL_MASTER_ENTRY_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case all_association_user_ActionType.GET_ALL_MASTER_ENTRY_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              get_all_master_entry_data: action.result,
            });
          case all_association_user_ActionType.GET_ALL_MASTER_ENTRY_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
  
                      // add association mASTER ENTRY
          case all_association_user_ActionType.CREATE_MASTER_ENTRY_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case all_association_user_ActionType.CREATE_MASTER_ENTRY_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_association_master_entry: action.result,
            });
          case all_association_user_ActionType.CREATE_MASTER_ENTRY_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });

             // Edit mASTER ENTRY
          case all_association_user_ActionType.EDIT_MASTER_ENTRY_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case all_association_user_ActionType.EDIT_MASTER_ENTRY_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              edit_association: action.result,
            });
          case all_association_user_ActionType.EDIT_MASTER_ENTRY_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });

                       // add user get
          case all_association_user_ActionType.ENABLE_MASTER_ENTRY_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case all_association_user_ActionType.ENABLE_MASTER_ENTRY_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              enableDisableMasterEntry : action.result,
            });
          case all_association_user_ActionType.ENABLE_MASTER_ENTRY_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });

        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default all_association_user_master_entry_reducer;
    
    
    