import * as logoutActionType from "../../actions/action.logout/action.type";


const logout = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    verify_logout: null,
    verify_logout_status:null,
    verify_logout_error:null,
    
    },
    action
) =>{

    switch (action.type) {
    

           //add new order
        case logoutActionType.POST_LOGOUT_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case logoutActionType.POST_LOGOUT_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            verify_logout: action.result,
          });
        case logoutActionType.POST_LOGOUT_ERROR:
          return Object.assign({}, state, {
            verify_logout_status: action.status,
            verify_logout_error: action.error,
          });

        default:
          return state;
      }
    };
    export default logout;
    
    
    