import * as constance from "../../constance/action.constance";
import {  

  //change password
  CHANGE_PASSWORD_USERS_REQ,
  CHANGE_PASSWORD_USERS__SUCC,
  CHANGE_PASSWORD_USERS__ERR,

  //FORGET PASSWORD
  
  FORGET_PASSWORD_ERR,
  FORGET_PASSWORD_SUCC,
  FORGET_PASSWORD_REQ,

  //RESET  PASSWORD

  RESET_PASSWORD_REQ,
  RESET_PASSWORD_ERR,
  RESET_PASSWORD_SUCC,


  } from "./action.type";

  


//ADD USERS

export function change_password_user_success(result) {
  return {
    type: CHANGE_PASSWORD_USERS__SUCC,
    result,
    status: constance.SUCCESS,
  };

}
export function change_password_request(data,callback) {
  return {
    type:CHANGE_PASSWORD_USERS_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function change_password_error(error) {
  return {
    type:CHANGE_PASSWORD_USERS__ERR,
    error: error,
    status: constance.ERROR,
  };
}


//FORGET PASSWORD

export function forget_password_success(result) {
  return {
    type: FORGET_PASSWORD_SUCC,
    result,
    status: constance.SUCCESS,
  };

}
export function forget_password_request(data,callback) {
  return {
    type:FORGET_PASSWORD_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function forget_password_error(error) {
  return {
    type:FORGET_PASSWORD_ERR,
    error: error,
    status: constance.ERROR,
  };
}

//RESET PASSWORD

export function reset_password_success(result) {
  return {
    type: RESET_PASSWORD_SUCC,
    result,
    status: constance.SUCCESS,
  };

}
export function reset_password_request(data,callback) {
  return {
    type: RESET_PASSWORD_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function reset_password_error(error) {
  return {
    type: RESET_PASSWORD_ERR,
    error: error,
    status: constance.ERROR,
  };
}

