import * as constance from "../../constance/action.constance";
import { 
  
  // POST_VERIFY_COUPON_BY_ID

  POST_LOGOUT_REQUEST ,
  POST_LOGOUT_ERROR, 
  POST_LOGOUT_SUCCESS,

} from "./action.type";


//VERIFY_logout
export function logout_success(result) {
  return {
    type:   POST_LOGOUT_SUCCESS,
    result,
    status: constance.SUCCESS,
  };
}
export function logout_request(data,callback) {
  return {
    type:  POST_LOGOUT_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function logout_error(error) {
  return {
    type:  POST_LOGOUT_ERROR,
    error: error,
    status: constance.ERROR,
  };
}

