//Get getAllSubscriptionReport id 
export const GET_SUBSCRIPTION_REPORT_BY_USER_ID_REQ  = "GET_SUBSCRIPTION_REPORT_BY_USER_ID_REQ";
export const GET_SUBSCRIPTION_REPORT_BY_USER_ID_ERR  = "GET_SUBSCRIPTION_REPORT_BY_USER_ID_ERR";
export const GET_SUBSCRIPTION_REPORT_BY_USER_ID_SUCC = "GET_SUBSCRIPTION_REPORT_BY_USER_ID_SUCC";


//Get getAllPaymenet id 
export const GET_PAYMENT_REPORT_BY_USER_ID_REQ  = "GET_PAYMENT_REPORT_BY_USER_ID_REQ";
export const GET_PAYMENT_REPORT_BY_USER_ID_ERR  = "GET_PAYMENT_REPORT_BY_USER_ID_ERR";
export const GET_PAYMENT_REPORT_BY_USER_ID_SUCC = "GET_PAYMENT_REPORT_BY_USER_ID_SUCC";


//EDIT getAllPaymenet id 
export const EDIT_PAYMENT_REPORT_BY_USER_ID_REQ  = "EDIT_PAYMENT_REPORT_BY_USER_ID_REQ";
export const EDIT_PAYMENT_REPORT_BY_USER_ID_ERR  = "EDIT_PAYMENT_REPORT_BY_USER_ID_ERR";
export const EDIT_PAYMENT_REPORT_BY_USER_ID_SUCC = "EDIT_PAYMENT_REPORT_BY_USER_ID_SUCC";

// //EDIT users id 
// export const EDIT_USERS_BY_USER_ID_REQ  = "EDIT_USERS_BY_USER_ID_REQ";
// export const EDIT_USERS_BY_USER_ID_ERR  = "EDIT_USERS_BY_USER_ID_ERR";
// export const EDIT_USERS_BY_USER_ID_SUCC = "EDIT_USERS_BY_USER_ID_SUCC";


//PAYMENTreport
export const DELETE_PAYMENT_ID_REQ  = "DELETE_PAYMENT_ID_REQ";
export const DELETE_PAYMENT_ID_ERR  = "DELETE_PAYMENT_ID_ERR";
export const DELETE_PAYMENT_ID_SUCC = "DELETE_PAYMENT_ID_SUCC";