import * as all_import_user_ActionType from "../../actions/action.excel/action.type";

const all_imports = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    import_all_user:null,
    },
    action
) =>{

    switch (action.type) {
    
          // all import user get
          case all_import_user_ActionType.IMPORT_USERS_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case all_import_user_ActionType.IMPORT_USERS_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              import_all_user: action.result,
            });
          case all_import_user_ActionType.IMPORT_USERS_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });


               
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default all_imports;
    
    
    