import { createBrowserHistory } from "history";
import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {

//import usesrs
import_all_users_err,
import_all_users_success,
} from './action.function.js';
import {
  API_URL,
} from '../../api/api.js';
import { API } from '../../../utils/networkApi';
import { isLoading } from '../action.loader/action.function.js';
import {
  IMPORT_USERS_REQ,
} from './action.type';
import toaster from "utils/ReactTostify.js";


const history = createBrowserHistory();




//import users USER



function* import_user_Saga(payload) {
  const { data, callback } = payload;
  console.log("data sent to delete saga", data)
  try {
    const store = yield select();

    console.log("store values ==========", API.getHeaders())
    yield put(isLoading(true));
    let response = yield call(API.post, API_URL.IMPORT_USER,data, API.getHeaders());
    yield put(isLoading(false));
    const result = response.data;
    console.log("add plan response saga", result);
    yield put(import_all_users_success(result))
    callback(null, result);
  }
  catch (error) {
    yield put(import_all_users_err(error));
    callback(error, null);
    yield put(isLoading(false));
    toaster("error", error);
  }
}
export default function* all_import_user_Saga() {

  yield takeEvery(IMPORT_USERS_REQ, import_user_Saga);
} 