import { createBrowserHistory } from "history";
import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {

  // all family_members 
  get_all_normel_users_err,
  get_all_normel_users_success,
  get_all_normel_users_req,



  //EDIT USERS FUNCTION
  edit_normel_users_by_user_id_success,
  edit_normel_users_by_user_id_err,

  //enable USERS FUNCTION
  enable_disble_normel_user_error,
  enable_disable_normel_user_success,


  //reset users
  reset_user_success,
  reset_user_error,


} from './action.function.js';
import {
  API_URL,
} from '../../api/api.js';
import { API } from '../../../utils/networkApi';
import { isLoading } from '../action.loader/action.function.js';
import {

  GET_ALL_NORMAL_USERS_REQ,
  Enable_NORMAL_USERS_BY_USER_ID_REQ,
  EDIT_NORMAL_USERS_BY_USER_ID_REQ,




} from './action.type';
import toaster from "utils/ReactTostify.js";


const history = createBrowserHistory();


function* normel_users_Saga(payload) {
  const { data, callback } = payload;
  try {
    const store = yield select();
 
    console.log("store values ==========", API.getHeaders())
    yield put(isLoading(true));
    let response = yield call(API.get, API_URL.GET_NORMEL_USER, API.getHeaders());

    yield put(isLoading(false));
    const result = response.data;
    console.log("all  saga result === ", result);
    yield put(get_all_normel_users_success(result));
    callback(null, result);


  }
  catch (error) {
    yield put( get_all_normel_users_err(error));
    yield put(isLoading(false));
    callback(error, null);
  }
}

//EDIT users
function* edit_normel_users_Saga(payload) {
  const { data, callback } = payload;
  console.log("data sent to edit plane saga", data)
  try {
 
    yield put(isLoading(true));
    console.log("store values ==========", API.getHeaders())
    let response = yield call(API.post, API_URL.Edit_NORMEL_USER, data, API.getHeaders());
    yield put(isLoading(false));

    const result = response.data;
    console.log("add plan response saga", result);
    yield put(edit_normel_users_by_user_id_success(result))
    callback(null, result);

   
  }
  catch (error) 
  {
    yield put(edit_normel_users_by_user_id_err(error));
    callback(error, null);
    yield put(isLoading(false));
  }
}

// //ENABLE USER
// function* enable_disable_user_Saga(payload) {
//   const { data, callback } = payload;
//   console.log("data sent to delete saga", data)
//   try {
//     const store = yield select();

//     console.log("store values ==========", API.getHeaders())
//     // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
//     yield put(isLoading(true));
//     let response = yield call(API.post, API_URL.Enable_disable_user, data, API.getHeaders());
//     yield put(isLoading(false));
//     const result = response.data;
//     console.log("add plan response saga", result);
//     yield put(enable_disable_user_success(result))
//     callback(null, result);
//   }
//   catch (error) {
//     yield put(enable_disble_error(error));
//     callback(error, null);
//     yield put(isLoading(false));
//   }
// }

//ENABLE USER
function* reset_user_normel_Saga(payload) {
  const { data, callback } = payload;
  console.log("data sent to delete saga", data)
  try {
    const store = yield select();

    console.log("store values ==========", API.getHeaders())
    // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
    yield put(isLoading(true));
    let response = yield call(API.post, API_URL.ENABLE_DISABLE_NORMEL_USER, data, API.getHeaders());
    yield put(isLoading(false));
    const result = response.data;
    console.log("add plan response saga", result);
    yield put(enable_disable_normel_user_success(result))
    callback(null, result);
  }
  catch (error) {
    yield put(enable_disble_normel_user_error(error));
    callback(error, null);
    yield put(isLoading(false));
 
  }
}
export default function* all_user_normel_Saga() {
  yield takeEvery(GET_ALL_NORMAL_USERS_REQ, normel_users_Saga);
  yield takeEvery(Enable_NORMAL_USERS_BY_USER_ID_REQ,reset_user_normel_Saga);
  yield takeEvery(EDIT_NORMAL_USERS_BY_USER_ID_REQ,edit_normel_users_Saga);
} 