import * as constance from "../../constance/action.constance";
import { 
//  GET ORDER_DETAILS_BY_ORDER_ID
GET_SUBSCRIPTION_REPORT_BY_USER_ID_REQ,
GET_SUBSCRIPTION_REPORT_BY_USER_ID_SUCC,
GET_SUBSCRIPTION_REPORT_BY_USER_ID_ERR,


//  GET ORDER_DETAILS_BY_ORDER_ID
GET_PAYMENT_REPORT_BY_USER_ID_REQ,
GET_PAYMENT_REPORT_BY_USER_ID_SUCC,
GET_PAYMENT_REPORT_BY_USER_ID_ERR,
 
// EDIT ORDER_DETAILS_BY_ORDER_ID
EDIT_PAYMENT_REPORT_BY_USER_ID_ERR,
EDIT_PAYMENT_REPORT_BY_USER_ID_REQ,
EDIT_PAYMENT_REPORT_BY_USER_ID_SUCC,

// EDIT USERS_BY_ORDER_ID
EDIT_USERS_BY_USER_ID_ERR,
EDIT_USERS_BY_USER_ID_REQ,
EDIT_USERS_BY_USER_ID_SUCC,

//DELETE PAYMENT
DELETE_PAYMENT_ID_ERR,
DELETE_PAYMENT_ID_REQ,
DELETE_PAYMENT_ID_SUCC



} from "./action.type";

// GET ORDER_DETAILS_BY_ORDER

export function get_subscription_report_by_user_id_req(data,callback) {
  return {
    type:  GET_SUBSCRIPTION_REPORT_BY_USER_ID_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_subscription_report_by_user_id_err(error) {
  return {
    type: GET_SUBSCRIPTION_REPORT_BY_USER_ID_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_subscription_report_by_user_id_success(result) {
  return {
    type: GET_SUBSCRIPTION_REPORT_BY_USER_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };
}


// GET ORDER_DETAILS_BY_ORDER

export function get_payment_report_by_user_id_req(data,callback) {
  return {
    type:  GET_PAYMENT_REPORT_BY_USER_ID_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_payment_report_by_user_id_success(result) {
  return {
    type: GET_PAYMENT_REPORT_BY_USER_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };
}

export function get_payment_report_by_user_id_err(error) {
  return {
    type: GET_PAYMENT_REPORT_BY_USER_ID_ERR,
    error,
    status: constance.ERROR,
  };
}



// EDIT_payment_report_by_user_id_req

export function edit_payment_report_by_user_id_req(data,callback) {
  return {
    type:EDIT_PAYMENT_REPORT_BY_USER_ID_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function edit_payment_report_by_user_id_success(result) {
  return {
    type: EDIT_PAYMENT_REPORT_BY_USER_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };

}

export function edit_payment_report_by_user_id_err(error) {
  return {
    type: EDIT_PAYMENT_REPORT_BY_USER_ID_ERR,
    error: error,
    status: constance.ERROR,
  };
}


//DELETE PAYMENT REPORT

export function delete_payment_success(result) {
  return {
    type: DELETE_PAYMENT_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };

}
export function delete_payment_request(data,callback) {
  return {
    type: DELETE_PAYMENT_ID_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function delete_payment_error(error) {
  return {
    type:DELETE_PAYMENT_ID_ERR,
    error: error,
    status: constance.ERROR,
  };
}