//ADD PLANES 
export const ADD_SUBSCRIPTION_PLAN_REQUEST =   "ADD_SUBSCRIPTION_PLAN_REQUEST";
export const ADD_SUBSCRIPTION_PLAN_SUCCESS =   "ADD_SUBSCRIPTION_PLAN_SUCCESS";
export const ADD_SUBSCRIPTION_PLAN_ERROR   =   "ADD_SUBSCRIPTION_PLAN_ERROR";

//EDIT PLANES 
export const EDIT_SUBSCRIPTION_PLAN_REQUEST =   "EDIT_SUBSCRIPTION_PLAN_REQUEST";
export const EDIT_SUBSCRIPTION_PLAN_SUCCESS =   "EDIT_SUBSCRIPTION_PLAN_SUCCESS";
export const EDIT_SUBSCRIPTION_PLAN_ERROR   =   "EDIT_SUBSCRIPTION_PLAN_ERROR";

//DELETE PLANES 
export const DELETE_SUBSCRIPTION_PLAN_REQUEST =   "DELETE_SUBSCRIPTION_PLAN_REQUEST";
export const DELETE_SUBSCRIPTION_PLAN_SUCCESS =   "DELETE_SUBSCRIPTION_PLAN_SUCCESS";
export const DELETE_SUBSCRIPTION_PLAN_ERROR   =   "DELETE_SUBSCRIPTION_PLAN_ERROR";