import React from "react";

let token = localStorage.getItem("token");

const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: token ? '/dashboard' : '/sign-in', // Path based on token presence
    // authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: true,
    // authenticatedEntryPath: '/app/sales/dashboard',
    // unAuthenticatedEntryPath: '/sign-in',
}

export default appConfig
