// import { config_file } from "../../../utils/env_config";
import { config_file } from "utils/env_config";
//export const API_URL_ROOT ="http://127.0.0.1:4000"
export const API_URL_ROOT = config_file[process.env["NODE_ENV"]].API_DOMAIN_NAME;

export const SUCCESS = "success";
export const ERROR = "error";
export const REQUESTING = "requesting";
export const FAIL = "fail";
export const LOADER ="LOADER";
export const PERSIST_STORE_NAME = 'admin'
