import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {  
   free_trail_error,
   free_trail_success,
  } from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import {isLoading} from '../action.loader/action.function.js';
    import {FREE_TRIAL_REQ} from './action.type'; 
    import toaster from "../../../utils/ReactTostify";
    
      function* free_trail_plane_Saga(payload) {
        const {data,callback} =payload;
        console.log("data sent to add family member saga", data)
        try {
            const store = yield select();
            // const headers = {
            //     Authorization: "Bearer " + store.user.token,
            //     "Content-Type": "application/json"
            //   };
            console.log("store values ==========", API.getHeaders())
            
          // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
          yield put(isLoading(true));
          let response = yield call(API.post,API_URL.UPDATE_SETTING,data,API.getHeaders());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("free trail  response saga",result);
          yield put(free_trail_success(result))
          callback(null,result);
        }
        catch(error) {
          yield put(free_trail_error(error));
          callback(error,null);
          yield put(isLoading(false));
          // toaster("error", error);
        }
      }     
export default function* free_trail_Saga() { 
    yield takeEvery(FREE_TRIAL_REQ,free_trail_plane_Saga);

} 